import React, { useState, useEffect } from 'react';
import { BrowserProvider, Contract, formatUnits, formatEther, parseUnits, parseEther } from 'ethers';
import { ERC20_ABI } from "../../abis/erc20";
import { BiDislike, BiLike, BiFlag } from "react-icons/bi";
import governanceImg from '../../governance.png';
import StandardVotingABI from '../../abis/StandardVoting.json'; // Standard Voting ABI
import SETTINGS from '../../SETTINGS';

const StandardVotingForm = ({ provider, proposal, onVote }) => {
  const [votingFee, setVotingFee] = useState(0); // To store voting fee from contract
  const [tokenBalance, setTokenBalance] = useState(0); // To store fetched token balance
  const [fixedVotingTokenAmount, setFixedVotingTokenAmount] = useState(0); // Amount of DAO tokens needed for voting
  const [rewardPercentage, setRewardPercentage] = useState(0); // Reward percentage
  const [isLoading, setIsLoading] = useState(false); // To show loading spinner
  const [isApproved, setIsApproved] = useState(false); // To track if tokens are approved
  const [vote, setVote] = useState(null); // To track user vote: yes/no
  const [hasVoted, setHasVoted] = useState(false); // Track if the user has voted
  const [proposer, setProposer] = useState(""); // Track if the user has voted
  const [userAddress, setUserAddress] = useState(""); // Track if the user has voted

  // Fetch voting details and token balance from the blockchain
  useEffect(() => {

    setProposer(proposal.proposer);
    const fetchVotingDetails = async () => {
    

      try {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const uAdd = await signer.getAddress();
        setUserAddress(uAdd);
        // Fetch DAO token balance
        const tokenContract = new Contract(SETTINGS.daoToken, ERC20_ABI, signer);
        const balance = await tokenContract.balanceOf(uAdd);
        setTokenBalance(balance.toString());

        // Fetch voting details from the StandardVoting contract
        const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer);
        const fetchedVotingFee = await daoContract.votingFee();
        const fetchedTokenAmount = await daoContract.fixedVotingTokenAmount();
        const fetchedReward = await daoContract.rewardPercentage();

        // Check if getVoterHistory is part of the contract
        if (daoContract.getVoterHistory) {
          const voters = await daoContract.getVoterHistory(proposal.index);

          // Check if the current user has already voted
          const currentUserAddress = await signer.getAddress();
          const alreadyVoted = voters[0].includes(currentUserAddress); // Check if the user address is in the voter list
          setHasVoted(alreadyVoted);
        } else {
          console.warn('getVoterHistory is not available in the contract.');
        }

        setVotingFee(formatEther(fetchedVotingFee)); // Convert fee to human-readable format
        setFixedVotingTokenAmount(fetchedTokenAmount.toString());
        setRewardPercentage(fetchedReward.toString());

      } catch (error) {
        console.error("Error fetching voting details:", error);
      }
    };

    fetchVotingDetails();
  }, [proposal.index]);

  // Function to handle DAO token approval
  const handleApprove = async () => {
    
    setIsLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        
      // Get the DAO token contract
      const tokenContract = new Contract(SETTINGS.daoToken, ERC20_ABI, signer);

      // Approve the DAO contract to spend the voting token amount on the user's behalf
      const approvalTx = await tokenContract.approve(SETTINGS.standardDaoContract, fixedVotingTokenAmount);
      await approvalTx.wait();
      setIsApproved(true); // Mark as approved
    } catch (error) {
      console.error('Error approving tokens:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle vote submission
  const handleSubmit = async () => {
   
    setIsLoading(true); // Show loader

    try {
      // Check for valid proposal index
      if (typeof proposal.index === 'undefined' || proposal.index === null) {
        alert('Invalid proposal index');
        setIsLoading(false);
        return;
      }

      const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();

      const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVotingABI, signer);

      // Ensure you are sending the required native fee
      const feeInNativeCurrency = parseEther(votingFee); // Parse voting fee to correct format
  
      const voteBool = vote === 'yes';  // true for yes, false for no

      const tx = await daoContract.vote(
        proposal.index,  // Pass the correct proposal index
        voteBool,
        {
          value: feeInNativeCurrency, // Sending the required native currency fee
        }
      );

      await tx.wait(); // Wait for the transaction to complete
      console.log('Vote submitted!');

      // Refresh accordion after vote submission and hide voting form
      if (onVote) {
        onVote(proposal.index);  // Ensure the onVote callback updates the proposal list after voting
      }
    } catch (error) {
      console.error('Error submitting vote:', error);
    } finally {
      setIsLoading(false); // Stop loader
    }
  };

  return (
    <div>
      <br />
      <h2 className="supplies-header" style={{textAlign:"left"}}>Vote on Proposal</h2>
      <br />
      <p>Your balance: <img src={governanceImg} className='tokenIconColor' style={{ width: "20px", marginRight: "6px", marginLeft: "4px", marginBottom: "4px" }} alt={"DAOToken"}/><b class="lebel-weight">{tokenBalance} DAO</b></p>
      <p>Voting Fee: <b class="lebel-weight">{votingFee} POL</b></p>
      <p>Voting Fee: <b class="lebel-weight">{fixedVotingTokenAmount} DAO</b> <br/>
      <small class="smallText text-left">*This fee is returned upon proposal completion, along with a {rewardPercentage} % bonus in DAOTokens.</small> </p> {/* Display the fixedProposalTokenAmount */}
{userAddress.toLowerCase() != proposer.toLowerCase() &&(<>
      {hasVoted ? (
        <p>You have already voted on this proposal</p>
      ) : (
        <form>
  <div>
    <label className='mb-2'>Choose your vote to support or oppose the proposal:</label>
    <div className='mb-2'>
      <input
        type="radio"
        value="yes"
        checked={vote === 'yes'}
        onChange={() => setVote('yes')}
        required
      />
      <BiLike className="vote-yes-v reaction-buttons" /> <label> (Yes)</label> 
      <input className='radiospace'
        type="radio"
        value="no"
        checked={vote === 'no'}
        onChange={() => setVote('no')}
        required
      />
      <BiDislike className="vote-no-v reaction-buttons" /><label> (No)</label>
    </div>
  </div>

  {!isApproved ? (
    <button 
    className={`mb-3 castvote-button ${isLoading || !vote ? 'disabled-button' : ''}`} 

      type="button" 
      onClick={handleApprove} 
      disabled={isLoading || !vote} // Disable if loading or no vote selected
    >
      {isLoading ? 'Approving...approve & wait' : 'Cast Vote'}
    </button>
  ) : (
    <button 
    className={`mb-3 castvote-button ${isLoading || !vote ? 'disabled-button' : ''}`} 

      type="submit" 
      disabled={isLoading || !vote} // Disable if loading or no vote selected
      onClick={handleSubmit}
    >
      {isLoading ? 'Submitting...confirm & wait' : 'Submit Vote'}
    </button>
  )}
</form>

      )}
      </>)}
    </div>
  );
};

export default StandardVotingForm;
