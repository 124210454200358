// src/components/Balance.js
import React from 'react';

const Balance = ({ balance, wins, losses }) => (
  <div style={styles.container}>
    <div style={styles.balance}>
      <span style={styles.label}>Balance:</span> ${balance.toFixed(2)}
    </div>
    <div style={styles.statsContainer}>
      <div style={{ ...styles.stat, ...styles.wins }}>
        🏆 Wins: <span style={styles.statValue}>{wins}</span>
      </div>
      <div style={{ ...styles.stat, ...styles.losses }}>
        ❌ Losses: <span style={styles.statValue}>{losses}</span>
      </div>
    </div>
  </div>
);

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    maxWidth: '300px',
    margin: '20px auto',
    textAlign: 'center',
  },
  balance: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '15px',
  },
  label: {
    color: '#555',
    marginRight: '5px',
  },
  statsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  stat: {
    fontSize: '16px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  wins: {
    color: '#28a745',
  },
  losses: {
    color: '#dc3545',
  },
  statValue: {
    fontWeight: 'bold',
  },
};

export default Balance;
