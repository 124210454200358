// src/components/Controls.js
import React from 'react';

const Controls = ({ onStakeChange, onDurationChange }) => (
  <div style={styles.container}>
   
    <div style={styles.formGroup}>
      <label style={styles.label}>
        Asset
        <select style={styles.select}>
          <option>Bitcoin</option>
          {/* Add other assets if needed */}
        </select>
      </label>
    </div>
    <div style={styles.formGroup}>
      <label style={styles.label}>
        Duration
        <select style={styles.select} onChange={(e) => onDurationChange(e.target.value)}>
          <option value="15">15 seconds</option>
          <option value="30">30 seconds</option>
          {/* Add more durations if needed */}
        </select>
      </label>
    </div>
    <div style={styles.formGroup}>
      <label style={styles.label}>
        Stake
        <input
          type="number"
          defaultValue="10"
          style={styles.input}
          onChange={(e) => onStakeChange(e.target.value)}
        />
      </label>
    </div>
  </div>
);

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    maxWidth: '300px',
    margin: '20px auto',
  },
  header: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '15px',
  },
  formGroup: {
    width: '100%',
    marginBottom: '15px',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    color: '#555',
    marginBottom: '5px',
  },
  select: {
    padding: '8px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    backgroundColor: '#f9f9f9',
  },
  input: {
    padding: '8px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    backgroundColor: '#f9f9f9',
  },
};

export default Controls;
