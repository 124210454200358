import React, { useState, useEffect } from 'react';
import Chart from './Chart';
import Controls from './Controls';
import Betting from './Betting';
import Balance from './Balance';
import { Button, Spinner, Row, Col, Card } from 'react-bootstrap';

function CryptoBet() {
  const [data, setData] = useState([]);
  const [balance, setBalance] = useState(1000);
  const [wins, setWins] = useState(0);
  const [losses, setLosses] = useState(0);
  const [stake, setStake] = useState(10);
  const [duration, setDuration] = useState(15);
  const [currentPrice, setCurrentPrice] = useState(70152.76);

  // Mock price updates
  useEffect(() => {
    const interval = setInterval(() => {
      const newPrice = currentPrice + (Math.random() - 0.5) * 10;
      setCurrentPrice(newPrice);
      setData((prevData) => [...prevData.slice(-19), newPrice]);
    }, 1000);

    return () => clearInterval(interval);
  }, [currentPrice]);

  const handleBet = (bet) => {
    const targetPrice = currentPrice + (Math.random() - 0.5) * 10;
    const won = (bet === 'High' && targetPrice > currentPrice) || (bet === 'Low' && targetPrice < currentPrice);

    if (won) {
      setBalance(balance + stake * 0.7);
      setWins(wins + 1);
    } else {
      setBalance(balance - stake);
      setLosses(losses + 1);
    }
  };

  return (
    <div className="crypto-bet-container p-4 mt-4">
         <h2>Crypto Bet</h2>
         <hr />
      <Row className="g-4">
        <Col sm={12} md={5}>
          <Card className="shadow-sm">
            <Card.Body>
              {data.length > 0 ? (
                <Chart data={data} />
              ) : (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '400px' }}>
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
   
   
            </Card.Body>
          </Card>
        </Col>

        <Col sm={12} md={4}>
        <Card className="shadow-sm mb-4">
            <Card.Body>
              
              <Balance balance={balance} wins={wins} losses={losses} />
              <Betting currentPrice={currentPrice} onBet={handleBet} />
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={3}>
          <Card className="shadow-sm mb-4">
            <Card.Body>
              <Controls onStakeChange={setStake} onDurationChange={setDuration} />
              
              
            </Card.Body>
          </Card>

        
        </Col>
      </Row>
    </div>
  );
}

export default CryptoBet;
