import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  
  // Register the required components
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
  
  const Chart = ({ data }) => {
    const chartData = {
      labels: data.map((_, i) => i),  // Replace with time intervals if available
      datasets: [
        {
          label: 'Price',
          data: data,
          fill: true,
          backgroundColor: 'rgba(0, 123, 255, 0.2)',
          borderColor: '#007bff',
          borderWidth: 2,
          pointRadius: 0,
          tension: 0.3, // Creates a smooth curve
        },
      ],
    };
  
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          labels: {
            color: '#333',
            font: {
              size: 14,
              weight: 'bold',
            },
          },
        },
        tooltip: {
          backgroundColor: '#007bff',
          titleColor: '#fff',
          bodyColor: '#fff',
          cornerRadius: 5,
          displayColors: false,
        },
        title: {
          display: true,
          text: 'Bitcoin Price Over Time',
          color: '#333',
          font: {
            size: 18,
            weight: 'bold',
          },
          padding: {
            top: 10,
            bottom: 20,
          },
        },
      },
      scales: {
        x: {
          display: true,
          grid: {
            display: false,
          },
          ticks: {
            color: '#555',
            font: {
              size: 12,
            },
          },
        },
        y: {
          display: true,
          grid: {
            color: 'rgba(200, 200, 200, 0.3)',
          },
          ticks: {
            color: '#555',
            font: {
              size: 12,
            },
            callback: (value) => `$${value.toFixed(2)}`, // Formats y-axis labels as dollar values
          },
        },
      },
    };
  
    return (
      <div style={styles.chartContainer}>
        <Line data={chartData} options={options} />
      </div>
    );
  };
  
  const styles = {
    chartContainer: {
      width: '100%',
      height: '400px', // Set a height for better control over the layout
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#fff',
      margin: '20px auto',
    },
  };
  
  export default Chart;
  