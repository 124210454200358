import React, { useState, useEffect } from 'react';
import { Col, Card, Row, Button, Image, Badge, Table } from 'react-bootstrap';
import SETTINGS from '../SETTINGS';
import NodeSaleAnnouncement from './NodeSaleAnnouncement';
import CountdownTimer from './CountdownTimer';
import { useNavigate } from 'react-router-dom';
import { FaCoins, FaLayerGroup } from 'react-icons/fa';
import logoToken from '../logoToken.svg';

const NodeInfo = ({ provider, isConnected }) => {
  const [nodeTypes, setNodeTypes] = useState([1, 2, 3, 4, 5, 6]);
  const [holderContracts, setHolderContracts] = useState([]);
  const [totalLocked, setTotalLocked] = useState(0);
  const [circulating, setCirculating] = useState(0);
  const [nodeInfoData, setNodeInfoData] = useState([]);
  const navigate = useNavigate();
  const loadNodeArr = () => {
    var dict = SETTINGS.NODE_INFO
    const specificKeys = [1, 2, 3, 4, 5, 6,]; 
    var arr = [];
    for (var key in dict) {
      if (specificKeys.includes(key) || specificKeys.includes(parseInt(key))) {
        arr.push(dict[key]);
      }
    }

    setNodeInfoData(arr);
  };

  const openBuyNode = () => {
    navigate('/store');
  }
  const openCraft = () => {
    navigate('/craft');
  }

  useEffect(() => {
    loadNodeArr();
  }, []); // Runs once on component mount
  const nodeData = [
    { idRange: '0 - 1999', price: '25 USDC' },
    { idRange: '2000 - 3999', price: '50 USDC' },
    { idRange: '4000 - 5999', price: '75 USDC' },
    { idRange: '6000 - 7999', price: '100 USDC' },
    { idRange: '8000 - 9999', price: '125 USDC' },
    { idRange: '10000 - 11999', price: '150 USDC' },
    { idRange: '12000 - 13999', price: '175 USDC' },
    { idRange: '14000 - 15999', price: '200 USDC' },
    { idRange: '16000 - 17999', price: '225 USDC' },
    { idRange: '18000 - 20000', price: '250 USDC' }
  ];
  useEffect(() => {
    loadNodeArr();
    if (!provider) return;

  }, [provider]);  

  const displayedNodeKeys = ['1', '2', '3', '4', '5', '6','10', '20', '30', '40', '50', '60']; // Example keys, adjust these as needed

  return (
    <div className="info-container">
      <Row className="g-4">
        {/* Title */}
        <h2 className='liqnodes-header'>Liquidity Nodes</h2>
        <p className="hero-p">Detailed presentation of the Liquidity Node Collection, each designed to generate specific daily rewards, provide referral income, and offer DAO voting power, while simultaneously enhancing token liquidity through Power Cores.</p> 
        <p className='d-flex justify-content-center align-items-center mb-4'>
 
        </p>



        <Col xs={12} md={12} lg={6} className='mt-0'>
          <Card className="mb-4 custom-card">
            <Card.Body>
              <Card.Title className='mb-3'>
                <FaLayerGroup className="me-2" />Liquidity Nodes Collection
              </Card.Title>
              {nodeInfoData.map((node, index) => (
                <div className='pokemon-card mb-3 chat-message' key={index} style={{ padding: "10px 20px" }}>
                  {/* Title on top of the card */}
                  <h6 className='text-uppercase mb-2 mt-0'>{node.title}</h6>
                  <Row className="align-items-start">
                    {/* Image Column */}
                    <Col sm={4} xs={12} className='mb-1 mt-2'>
                      <Image src={node.imgUrl} rounded className="w-100" />
                    </Col>

                    {/* Price / Items needed Column */}
                    <Col sm={6} md={4} xs={12} className="mb-3 mt-2 d-flex flex-column justify-content-between">
                        {node.price > 0 ? (
                          <div className="buy-section d-flex flex-column align-items-center text-center">
                            <div className="d-flex flex-wrap justify-content-center align-items-center">
                            <Button 
                              variant="mt-1" 
                              className="buton-secondary d-flex align-items-center justify-content-center" 
                              style={{ width: '100%' }} // Button takes full width
                              onClick={openBuyNode}
                            >
                              Buy with WAWE
                            </Button>
                            <Button 
                              variant=" mt-2" 
                              style={{ width: '100%' }} // Button takes full width
                              onClick={openCraft} 
                              className="buton-secondary d-flex align-items-center justify-content-center" 
                            >
                              Craft With ...
                            </Button>
                              {node.craftElements.map((imgMod, index1) => {
                                const craftElement = Object.values(SETTINGS.NODE_INFO).find(node => node.imgUrl === imgMod);
                                return (
                            <React.Fragment key={index1}>
                              <div className="d-flex align-items-center mt-2">
                                <Image 
                                  src={imgMod} 
                                  rounded 
                                  className="me-2" 
                                  style={{ width: '45px', height: '45px' }}  // Smaller image size to fit one row
                                  title={craftElement?.hoverText} // Use the hoverText of the found element
                                />
                                {/* Optional text or additional content goes here if needed */}
                              </div>
                            </React.Fragment>
                                );
                              })}
                            </div>

                          </div>
                        ) : (
                          <div className="craft-section text-center d-flex flex-column align-items-center">
                            {/* <small><b>Craft with:</b></small>*/}
                            

                          </div>
                        )}
                      </Col>



                    {/* Rewards Column */}
                    <Col sm={4} xs={12} className='mb-1 text-end'>
                      {node.lifespan > 0 && (
                        <div className='d-block mb-3'>
                          <small><b>{node.lifespan}</b> days
                          </small>
                        </div>
                      )}
                      {node.dailyRewards > 0 && (
                        <div className='d-block mb-3'>
                          <small><b>{node.dailyRewards.toFixed(2)}</b> {SETTINGS.tokenSymbol}/day</small>
                        </div>
                      )}
                      <div className='d-block mb-3'>
                        <small>Referral: <b>{node.refferalPercent}%</b></small>
                      </div>
                      {node.dao_power > 0 && (
                        <div className='d-block mb-3'>
                          <small>DAO power: <b>{node.dao_power}</b></small>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={6} className='mt-0'>
          {/* <Card className="mb-3 custom-card">
            <Card.Body>
              <NodeSaleAnnouncement />
            </Card.Body>
          </Card>*/}

          
          <Card className="mb-4 custom-card">
            <Card.Body>
              <Card.Title><FaCoins className="me-2" />Nodes Supply Overview</Card.Title>
              <p class="justify">
              Track limited supplies of all Liquidity Node types, with secondary market options on OpenSea or other secondary market for trading and growth potential.
              </p>
              <div className="table-responsive mt-4">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>Node Name</th>
                      <th style={{ textAlign: "center" }}>Purchase price</th>
                      <th style={{ textAlign: "center" }}>Total Supply</th>
                    </tr>
                  </thead>
                  <tbody>
                  {nodeTypes.map((type, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>
                        {SETTINGS.NODE_INFO[type].title}</td>
                        <td style={{ textAlign: "right", paddingRight: "20px" }}>{(SETTINGS.NODE_INFO[type].price)} {SETTINGS.tokenSymbol}</td>
                        <td style={{ textAlign: "right", paddingRight: "20px" }}>{(SETTINGS.NODE_INFO[type].total)} NODES</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default NodeInfo;
