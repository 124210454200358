import React from 'react';
import MatrixEffect from './MatrixEffect'; // Import MatrixEffect component
import SETTINGS from '../SETTINGS';

function HeroSection() {
  return (
    <section className="hero">
      {/* Adjust speed to control how fast the characters fall */}
      <MatrixEffect className="matrix-effect" quantity={10000} speed={0.05} />
      <div className="hero-content">
        <h1 class="hero-h1">Recycle, Revitalize, Revamp</h1>
        <p class="hero-p">Unlock your crypto's liquidity and connect with a thriving open-source DeFi community</p>
        <a href="https://docs.waveswaps.com/" target='_blank' className="hero-button">Read the Docs</a>
        {SETTINGS.IS_TEST &&(<a href="https://faucet.waveswaps.com/" target='_blank' className="hero-button test-button" style={{marginLeft:"15px"}}>Faucet</a>)}
      </div>
    </section>
  );
}

export default HeroSection;
