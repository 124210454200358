import React, { useState, useEffect } from 'react';
import { BrowserProvider, Contract, formatUnits, formatEther, parseUnits } from 'ethers';
import governanceImg from '../../governance.png';
import { ERC20_ABI } from "../../abis/erc20";
import StandardVoting from '../../abis/StandardVoting.json';
import SETTINGS from "../../SETTINGS";

const StandardProposalForm = ({ provider, selectedAccount, isConnected, setActiveTab }) => {
  const [subject, setSubject] = useState(''); // Subject for the proposal
  const [category, setCategory] = useState(SETTINGS.proposalCategories[0]); // Default to the first category
  const [description, setDescription] = useState('');
  const [tokenBalance, setTokenBalance] = useState(0);
  const [proposalFee, setProposalFee] = useState(0);
  const [fixedProposalTokenAmount, setFixedProposalTokenAmount] = useState(0); // To store the fixed proposal token amount
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const fetchContractDetails = async () => {


      try {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();

        // Interact with DAO token contract
        const tokenContract = new Contract(SETTINGS.daoToken, ERC20_ABI, signer);
        const balance = await tokenContract.balanceOf(await signer.getAddress());
        setTokenBalance(balance.toString());

        // Interact with DAO contract to fetch the proposal fee and fixedProposalTokenAmount
        const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVoting, signer);
        const fetchedProposalFee = await daoContract.proposalFee();
        const fetchedFixedProposalTokenAmount = await daoContract.fixedProposalTokenAmount();

        setProposalFee(fetchedProposalFee.toString());
        setFixedProposalTokenAmount(fetchedFixedProposalTokenAmount.toString());

        console.log("Token balance:", balance.toString());
        console.log("Proposal fee:", fetchedProposalFee.toString());
        console.log("Fixed proposal token amount:", fetchedFixedProposalTokenAmount.toString());
      } catch (error) {
        console.error('Error fetching contract details:', error);
      }
    };

    fetchContractDetails();
  }, [provider]);

  const handleApprove = async () => {
    setIsLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      const tokenContract = new Contract(SETTINGS.daoToken, ERC20_ABI, signer);
      const approvalTx = await tokenContract.approve(SETTINGS.standardDaoContract, fixedProposalTokenAmount);
      await approvalTx.wait();
      setIsApproved(true);
    } catch (error) {
      console.error('Error approving tokens:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const daoContract = new Contract(SETTINGS.standardDaoContract, StandardVoting, signer);

      // Ensure proposalFee is correctly formatted in wei
      const feeInWei = parseUnits(proposalFee, 'wei');

      // Submit the proposal with selected subject, category, and description
      const tx = await daoContract.postProposal(subject, category, description, { value: feeInWei });
      await tx.wait();


      // Reset form fields after submission
      setSubject('');
      setCategory(SETTINGS.proposalCategories[0]);
      setDescription('');
      setActiveTab("list");
    } catch (error) {
      console.error('Error posting proposal:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2 className="supplies-header" style={{textAlign:"left"}}>New Standard Proposal</h2>
      <p>Your balance: <img src={governanceImg} className='tokenIconColor' style={{ width: "20px", marginRight: "6px", marginLeft: "4px", marginBottom: "4px" }} alt={"DAOToken"}/><b class="lebel-weight">{tokenBalance} DAO</b></p>
      <p>Proposal Fee: <b class="lebel-weight">{formatEther(proposalFee)} POL</b></p> {/* Display the proposal fee */}
      <p><label>Proposal Fee:</label> <img src={governanceImg} className='tokenIconColor' style={{ width: "20px", marginRight: "6px", marginLeft: "4px", marginBottom: "4px" }} alt={"DAOToken"}/><b class="lebel-weight">{fixedProposalTokenAmount} DAO </b><br/>
      <small class="smallText text-left">*This fee is returned upon proposal completion, along with a 20% bonus in DAOTokens.</small> </p> {/* Display the fixedProposalTokenAmount */}
      
      <form onSubmit={handleSubmit}>
        <div className="input-group-prop mt-2">
          <p className='form-label'>Proposal Title:</p>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value.slice(0, 50))} // Limit subject to 50 characters
            placeholder="Write a clear proposal title (max 50 characters)."
            maxLength="50"
            required
            className='form-select dropdown-prop'
            onFocus={(e) => e.target.placeholder = ""} // Clear placeholder on focus
            onBlur={(e) => e.target.placeholder = "Write a clear proposal title (max 50 characters)."} 
          />
        </div>
        <div>
          <p className='form-label mt-3'>Category:</p>
          <select
            value={category}
            className='form-select dropdown-prop'
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            {SETTINGS.proposalCategories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p className='form-label mt-3'>Proposal Content:</p>
          <textarea
          className='custom-select w-100 form-control subject-prop'
            value={description}
            placeholder="Briefly describe your proposal here (max 1000 characters). For additional details, feel free to include links to related content."
            maxLength="1000"
            onChange={(e) => setDescription(e.target.value)}
            required
            onFocus={(e) => e.target.placeholder = ""} // Clear placeholder on focus
            onBlur={(e) => e.target.placeholder = "Briefly describe your proposal here (max 1000 characters). For additional details, feel free to include links to related content."} 
          />
        </div>
        {!isApproved ? (
          <button type="button" onClick={handleApprove}  className='button-center mt-3 hero-button' disabled={isLoading}>
            {isLoading ? 'Approving...please wait' : 'Approve Proposal'}
          </button>
        ) : (
          <button type="submit" className='button-center mt-3 hero-button' disabled={isLoading}>
            {isLoading ? 'Submitting...please wait' : 'Submit Proposal'}
          </button>
        )}
      </form>
    
    </div>
  );
};

export default StandardProposalForm;
