// src/components/Betting.js
import React from 'react';

const Betting = ({ currentPrice, onBet }) => (
  <div style={styles.container}>
    <h1 style={styles.price}>BTC ${currentPrice.toFixed(2)}</h1>
    <div style={styles.buttonContainer}>
      <button onClick={() => onBet('High')} style={{ ...styles.button, ...styles.highButton }}>
        📈 High
      </button>
      <button onClick={() => onBet('Low')} style={{ ...styles.button, ...styles.lowButton }}>
        📉 Low
      </button>
    </div>
  </div>
);

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    maxWidth: '300px',
    margin: '20px auto',
    textAlign: 'center',
  },
  price: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '5px',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
  },
  highButton: {
    backgroundColor: '#28a745',
  },
  lowButton: {
    backgroundColor: '#dc3545',
  },
};

export default Betting;
